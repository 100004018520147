<template>
  <div class="project-properties">
    <template v-if="showUpcomingLabel">
      <div class="project-properties__item">
        <div class="project-properties__value">
          {{ upcomingLabel }}
        </div>
      </div>
    </template>
    <template v-else>
      <template v-for="i in items">
        <div
          v-if="i.value"
          :key="i.key"
          class="project-properties__item"
          :class="{ 'project-properties__item-stats': i.category === 'stats' }"
        >
          <div class="project-properties__label">
            {{ i.label }}
          </div>
          <div class="project-properties__value">{{ i.value }}</div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'

export default {
  computed: {
    ...mapState('Project', ['item']),
    properties() {
      return this.$path('metadata.residentialProperties', this.item)
    },
    customProperties() {
      return GetCustomField('project-properties', this.item.customFields) || []
    },
    items() {
      return [
        ...this.customProperties.map(i => ({
          key: i.key,
          label: i.title,
          value: i.value,
        })),
        ...this.enabledStatus.map(item => {
          return {
            key: item.key,
            label: item.label
              ? this.$t(`${item.label}`)
              : this.$t(`residentialStatus:${item.type}.${item.key}`),
            value: this.customProperties[item.key] || this.getStat(item.key),
            category: 'stats',
          }
        }),
      ]
    },
    enabledStatus() {
      return [
        { key: 'sale', type: 'sale' },
        { key: 'sold', type: 'sale' },
        { key: 'rent', type: 'rent' },
        { key: 'rented', type: 'rent' },
        { key: 'reserved', type: 'sale' },
        { key: 'saleUpcoming', label: 'residentialStatus:sale.upcoming', type: 'sale' },
        { key: 'rentUpcoming', label: 'residentialStatus:rent.upcoming', type: 'rent' },
      ].filter(e => this.getStat(e.key))
    },
    stats() {
      return this.item.stats || {}
    },
    showUpcomingLabel() {
      return this.getStat('total') === 0 || this.getStat('total') === this.getStat('upcoming')
    },
    upcomingLabel() {
      if (KvassConfig.get('customLabels.upcoming')) return KvassConfig.get('customLabels.upcoming')

      if (this.stats.saleUpcoming) return this.$t('residentialStatus:sale.upcoming')
      if (this.stats.rentUpcoming) return this.$t('residentialStatus:rent.upcoming')

      return this.$t('upcomingLabel')
    },
  },
  methods: {
    getStat(key) {
      if (this.stats[key]) return this.stats[key]

      let keys = key instanceof Array ? key : [key]
      return keys.reduce((r, c) => r + KvassConfig.get(`stats.${c}`) || 0, 0)
    },
    convertPrice(value) {
      if (!value) return

      return `${value
        .toLocaleString({
          minimumFractionDigits: 0,
        })
        .replace(new RegExp(',', 'g'), '.')},-`
    },

    getProperty(key) {
      if (!this.properties || !this.properties[key].length) return

      switch (key) {
        case 'price':
          return this.convertPrice(Math.min(...this.properties[key]))
        case 'grossArea':
          return `${Math.min(...this.properties[key])} - ${Math.max(...this.properties[key])}`
        default:
          return undefined
      }
    },
  },

  components: {},
}
</script>

<style lang="scss">
.project-properties {
  z-index: 1;
  max-width: 1400px;
  background-color: var(--primary);
  box-shadow: inset 0 10px 15px 0px #71717138;
  display: flex;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);
  width: 100%;
  left: 50%;
  position: relative;
  align-items: center;
  justify-content: center;

  @include respond-below('phone') {
    bottom: 0;
    width: 100%;
    position: initial;
    transform: none;
  }

  &__item {
    flex-grow: 1;
    color: var(--primary-contrast);
    line-height: 1;
    display: flex;

    flex-direction: column;
    justify-content: center;
    gap: 0.75em;
    padding: 1.5rem;

    & > * {
      text-align: center;
    }

    @include respond-below('tablet') {
      width: 120px;
      flex-grow: 1;
      padding: 1.5rem 0.5rem;
    }

    &--coming {
      width: initial;
      padding: 1.5rem;
      font-size: 0.7em;

      & > * {
        margin: 0;
      }
    }
  }

  &__value {
    font-size: 1.2em;
    font-weight: bold;
    color: white;

    @include respond-below('phone') {
      font-size: 0.75em;
    }
  }

  &__label {
    font-weight: bold;
    opacity: 0.5;
    font-size: 1em;

    @include respond-below('phone') {
      font-size: 0.7em;
    }
  }
}
</style>
