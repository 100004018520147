import CookieManager from '@kvass/cookie-manager'
import store from './store'

let CM = {}

function loadCM(i18n) {
  const projectId = store.state.Root.item.id

  let consents = [
    {
      id: 'required',
      required: true,
      title: i18n.$t('consentRequiredTitle'),
      description: i18n.$t('consentRequiredDescription'),
    },
    {
      id: 'statistics',
      default: true,
      title: i18n.$t('consentStatisticsTitle'),
      description: i18n.$t('consentStatisticsDescription'),
    },
    {
      id: 'marketing',
      default: false,
      title: i18n.$t('consentMarketingTitle'),
      description: i18n.$t('consentMarketingDescription'),
    },
  ]

  CM = new CookieManager({
    title: i18n.$t('consentDialogTitle'),
    description: i18n.$t('projectConsentDialogDescription', {
      link: `<a target="_blank" href="/api/legal/privacy/project/${projectId}">${i18n
        .$t('privacyPolicyTitle')
        .toLowerCase()}</a>`,
    }),
    labels: {
      accept: i18n.$t('consentDialogLabelAccept'),
      acceptAll: i18n.$t('consentDialogLabelAcceptAll'),
      confirm: i18n.$t('consentDialogLabelConfirm'),
      configure: i18n.$t('consentDialogLabelConfigure'),
    },
    consents,
    onChange(res) {
   
      if (Kvass) Kvass.emit('consent:change', res)
    },
  })

  if (Kvass && CM) {
    Kvass.on('consent:show', CM.show)
    Kvass.on('consent:get', cb => cb(CM.getConsents()))
  }

  return CM
}

export default loadCM
