<template>
  <ResidentialTemplates
    v-if="$path('references', value)"
    :value="value"
    class="residential-templates"
  />
</template>

<script>
import { GetCustomField } from '@/utils'
import { mapState } from 'vuex'

import { Items as ResidentialTemplates } from '@kvass/template-residential-template'

export default {
  computed: {
    ...mapState('Project', ['item']),
    value() {
      return GetCustomField('residential-templates', this.item.customFields) || []
    },
  },
  components: {
    ResidentialTemplates,
  },
}
</script>
