<template>
  <div v-if="sellingpoints && sellingpoints.length" class="project-selling-points">
    <template v-for="(item, index) in sellingpoints">
      <div v-if="item" class="project-selling-points__item" :key="'sellingpoint--' + index">
        <img v-if="item.icon" :src="item.icon.url" :alt="item.icon.description" height="64" />
        <div v-html="item.content"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'

export default {
  computed: {
    ...mapState('Project', ['item']),
    sellingpoints() {
      return GetCustomField('selling-points', this.item.customFields)
    },
  },
}
</script>

<style lang="scss">
.project-selling-points {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: clamp(3rem, 4vw, 6rem);

  @include respond-below('phone') {
    grid-template-columns: 1fr;
  }

  &__item {
  }
}
</style>
