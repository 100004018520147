<template>
  <div v-if="value.length" class="roles">
    <div class="roles__list">
      <div class="roles__item" v-for="(item, index) in value" :key="index">
        <div class="roles__avatar" :style="{ backgroundImage: `url(${item.avatar})` }"></div>
        <div class="roles__content">
          <div class="roles__name">{{ item.name }}</div>
          <div v-if="item.title" class="roles__user-title">{{ item.title }}</div>
          <a v-if="item.email" class="roles__email" :href="`mailto:${item.email}`"
            ><FontAwesomeIcon :icon="['fal', 'envelope']" />{{ $t('sendEmail') }}</a
          >
          <a v-if="item.phone" class="roles__phone" :href="`tel:${item.phone}`"
            ><FontAwesomeIcon :icon="['fal', 'mobile']" />{{ item.phone }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
}
</script>

<style lang="scss">
.roles {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    @include respond-below('tablet') {
      gap: 1rem;
      grid-template-columns: 1fr;
    }
  }

  &__title {
    margin-bottom: 1.5rem;

    @include respond-below('phone') {
      text-align: center;
    }
  }

  &__avatar {
    --size: 100px;

    height: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
    background-size: cover;
    background-position: center;

    flex-shrink: 0;
  }

  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__user-title {
    margin: 2px 0;
  }

  &__item {
    color: var(--body-color);
    background: transparent;
    flex-grow: 1;

    display: flex;
    gap: 2rem;
    align-items: center;
    a {
      color: var(--body-color);
    }
    @include respond-below('phone') {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .roles {
        &__name {
          margin-top: 10px;
        }

        &__avatar {
          --size: 80px;
        }

        &__email,
        &__phone {
          display: block;
          margin-top: 3px;
        }
      }
    }
  }

  &__name {
    font-weight: bold;
    font-size: 1.1em;
  }

  &__email,
  &__phone {
    opacity: 0.6;
    text-decoration: none;
    white-space: nowrap;

    margin-right: 10px;

    svg {
      margin-right: 7px;
    }
  }
}
</style>
