<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <div class="hero__wrapper section__hero">
        <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
        <Hero class="project-page__hero">
          <template #overlay>
            <Lead class="project-page__lead" :action-label="leadButtonTitle">
              <template #actions>
                <template v-for="(button, index) in buttons">
                  <BrochureComponent
                    v-if="button.type === 'brochure'"
                    :value="brochure"
                    class="residential-info__brochure"
                    :key="index + 'brochure'"
                    :label="button.content"
                    theme="secondary"
                    placement="top"
                  />

                  <ButtonComponent
                    v-if="['flatfinder', 'residentials'].includes(button.type)"
                    :label="button.content"
                    :icon="['far', 'arrow-down']"
                    tag="router-link"
                    :to="`#${button.type}`"
                    theme="secondary"
                    :key="index"
                  />
                </template>
              </template>
            </Lead>
          </template>
        </Hero>
        <Properties />
      </div>

      <section class="section section__info">
        <div class="container">
          <Info>
            <ProgressBar v-if="showProgressBar" />
          </Info>

          <div class="row center middle">
            <BrochureComponent
              :value="$path('media.brochure', item) || []"
              class="triggers__brochure"
            />

            <ButtonComponent
              theme="primary"
              :icon="['far', 'arrow-up']"
              :label="$t('getInTouch')"
              @click="gotoLead"
            />
          </div>
          <Roles />
          <Openhouse v-if="showOpenhouse" />
          <Map v-if="hasResidentials" :zoom="9.5" />
        </div>
      </section>

      <section v-if="$path('flatfinders.length', item)" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" :delay="750" />
        <div class="container">
          <Flatfinder />
        </div>
      </section>
      <section v-if="$path('stats.total', item)" class="section section__residentials">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section v-if="showResidentialTemplates" class="section section__residential-templates">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-8rem" :delay="750" />
          <ResidentialTemplates />
        </div>
      </section>
      <section class="section section__attachments" v-if="$path('media.attachments.length', item)">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>

      <section
        v-if="$path('customFields.selling-points.0.content', item)"
        class="section section__selling-points"
      >
        <div class="container">
          <SellingPoints />
        </div>
      </section>

      <section v-if="additionalSections.hasItems" class="section section__additional-sections">
        <ScrollAnchor value="additional-sections" offset="-8rem" :delay="750" />
        <div
          :class="[
            { container: additionalSections.size !== 'full' },
            `additional-sections--${additionalSections.size}`,
          ]"
        >
          <AdditionalSections />
        </div>
      </section>

      <section v-if="$path('media.gallery.length', item)" class="section section__images">
        <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
        <Masonry :value="$path('media.gallery', item)" />
      </section>

      <section class="section section__map section--no-p">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <Map :scroll-zoom="false" />
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import Masonry from '@kvass/template-gallery/Types/Masonry'

import ScrollAnchor from '@kvass/scroll-anchor'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import BrochureComponent from '@/components/Brochure'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !this.$path('item.metadata.siteSettings.hideNoOpenhouse')
    },
    hasResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
    showProgressBar() {
      return (this.$path('item.customFields.project-progress') || []).some(
        i => i.description || i.icon,
      )
    },
    brochure() {
      return this.$path('item.media.brochure') || []
    },
    buttons() {
      if (!this.item.customFields) return []
      let items = GetCustomField('project-hero-buttons', this.item.customFields) || []
      if (!items.length || (!items.some(i => i.type) && this.hasResidentials))
        items = [
          {
            content: this.$t('findYourUnit'),
            type: this.$path('flatfinders.length', this.item) ? 'flatfinder' : 'residentials',
          },
        ]
      return items.filter(i => i.type)
    },
    leadButtonTitle() {
      return (this.buttons.find(i => i.type === 'lead') || {}).content || this.$tc('leadFormTitle')
    },
    additionalSections() {
      return {
        size: this.$path('additional-sections-settings.size', this.item.customFields) || 'medium',
        hasItems: this.$path('additional-sections.0.image', this.item.customFields),
      }
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return this.$path('residential-templates.references.length', this.item.customFields)
    },
  },

  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    gotoLead() {
      let target = document.querySelector(
        '.scroll-anchor--type-anchor.scroll-anchor--value-' + 'lead',
      )
      if (!target) return
      target.scrollIntoView({ behavior: 'smooth' })
    },
  },

  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
    BrochureComponent,
    Masonry,
  },
}
</script>

<style lang="scss">
.project-page {
  display: flex;
  flex-direction: column;

  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .section__selling-points {
    background-color: rgba(black, 0.05);
  }

  .section__images {
    padding-bottom: 0;
  }

  .section__info {
    .container {
      max-width: $projectInfoWidth;
      $responsiveGap: 4rem;
      display: flex;
      flex-direction: column;
      gap: 3rem 0;
      justify-content: center;

      .mgl-map {
        height: 350px;
        min-height: 100px;
      }

      & > *:nth-child(2) {
        gap: 3rem;
        flex-wrap: wrap;
      }

      @include respond-below('phone') {
        & > *:nth-child(2) {
          font-size: 0.8em;
          gap: 1rem;
        }

        .project-map {
          margin: -$outerSpacing;
        }
      }
    }
  }
  .additional-sections {
    $textOverMargin: 11rem;
    &--full {
      $maxWidth: 700px;
      .kvass-image-text__item:not(.kvass-image-text__item--text-over) {
        .kvass-image-text__content {
          max-width: $maxWidth;
        }
      }
      .kvass-image-text__item--text-over {
        .kvass-image-text__content {
          max-width: calc(#{$maxWidth} + #{$textOverMargin});
        }
      }
    }
  }
}
</style>
