<template>
  <MglMap v-if="$attrs.center" class="mgl-map" v-bind="{ ...$attrs, ...defaults }">
    <slot></slot>
    <MglMarker v-if="$attrs.center" :coordinates="$attrs.center"></MglMarker>
    <MglNavigationControl position="top-right" />
  </MglMap>
</template>

<script>
import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker, MglNavigationControl } from 'vue-mapbox'
import { Clone } from '@/utils'
import BrowserApiMixin from '@/mixins/browser-api'

const mapDefaults = {
  zoom: 11,
  center: [10.7522, 59.9139],
  accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
  mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
  dragPan: true,
  scrollZoom: true,
}

export default {
  mixins: [BrowserApiMixin('map')],
  data() {
    return {
      mapbox: null,
      mapDefaults: Clone(mapDefaults),
    }
  },
  computed: {
    defaults() {
      return Object.entries(this.mapDefaults).reduce((res, [key, value]) => {
        res[key] = this.$attrs[key] || value
        return res
      }, {})
    },
  },
  created() {
    this.mapbox = Mapbox
  },
  components: {
    MglMap,
    MglMarker,
    MglNavigationControl,
  },
}
</script>

<style lang="scss">
.mgl-map {
  border-radius: $border-radius;
  overflow: hidden;
  height: auto;
  width: auto;

  min-height: 600px;
  max-height: 800px;

  svg path {
    fill: var(--primary);
  }
}

.mapboxgl-ctrl-attrib {
  display: none;
}
</style>
