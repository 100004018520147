<template>
  <Roles class="residential-roles" :value="items" />
</template>

<script>
import { mapState } from 'vuex'
import Roles from '@/components/Roles'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.project.roles.salesman') || []
    },
  },
  components: {
    Roles,
  },
}
</script>

<style lang="scss">
.residential-roles {
  @include respond-above('phone') {
    .roles__list {
      grid-template-columns: 1fr;
    }
  }
}
</style>
