<template>
  <div class="residential-cover__wrapper">
    <Slider
      class="residential-cover"
      v-if="items.length"
      :value="items"
      :display-thumbnail="true"
      manual-navigation
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@kvass/media-render/Slider'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.residential-cover {
  background-color: css-alpha('primary', 0.07);
  position: absolute;
  height: 100%;
  width: 100%;
  &__wrapper {
    position: relative;
    height: 50vh;
    min-width: 50vw;
  }
}
</style>
