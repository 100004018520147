var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Loader',{attrs:{"value":_vm.promises.fetch,"theme":"default"}},[_c('div',{staticClass:"project-residential-page"},[_c('div',{staticClass:"project-residential-page__header section__header"},[_c('ResidentialCover'),_c('ResidentialSummary')],1),_c('section',{staticClass:"section section__info"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"grow-1"},[(_vm.showOpenhouse)?_c('ResidentialOpenhouse',{staticClass:"mb-6"}):_vm._e(),_c('ResidentialRoles')],1),_c('ResidentialInfo')],1)]),_c('section',{staticClass:"section section__properties"},[_c('div',{staticClass:"container"},[_c('ScrollAnchor',{attrs:{"value":"properties","offset":"-8rem","delay":750}}),_c('ResidentialProperties')],1)]),(_vm.$path('media.floorplan.length', _vm.residential))?_c('section',{staticClass:"section section__floorplan"},[_c('div',{staticClass:"container"},[_c('ScrollAnchor',{attrs:{"value":"floorplan","offset":"-8rem","delay":750}}),_c('ResidentialFloorplan')],1)]):_vm._e(),(
        _vm.$path('shop.published', _vm.residential) &&
          !_vm.$path('project.metadata.siteSettings.hideShop', _vm.residential)
      )?_c('section',{staticClass:"section section__shop section--no-p"},[_c('div',{staticClass:"container"},[_c('ScrollAnchor',{attrs:{"value":"shop","offset":"-8rem","delay":750}}),_c('ResidentialShopIntro')],1)]):_vm._e(),_c('section',{staticClass:"section__additional-info"},[_c('div',{staticClass:"container"},[_c('ScrollAnchor',{attrs:{"value":"additional-info","offset":"-8rem","delay":750}}),_c('ProjectAdditionalInfo')],1)]),_c('section',{staticClass:"section section__residential-templates"},[_c('div',{staticClass:"container"},[_c('ScrollAnchor',{attrs:{"value":"residential-templates","offset":"-8rem","delay":750}}),_c('ProjectResidentialTemplates')],1)]),_c('section',{staticClass:"section section__lead"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row wrap g-6"},[_c('ScrollAnchor',{attrs:{"value":"lead","offset":"-8rem","delay":750}}),_c('ResidentialLead',{staticClass:"grow-1"})],1)])]),(
        _vm.$path('media.attachments.length', _vm.residential) ||
          _vm.$path('project.media.attachments.length', _vm.residential)
      )?_c('section',{staticClass:"section section__attachments"},[_c('div',{staticClass:"container"},[_c('ScrollAnchor',{attrs:{"value":"attachments","offset":"-8rem","delay":750}}),_c('ResidentialAttachments')],1)]):_vm._e(),(_vm.$path('media.gallery.length', _vm.residential))?_c('section',{staticClass:"section section__images section--no-pb"},[_c('ScrollAnchor',{attrs:{"value":"gallery","offset":"-8rem","delay":750}}),_c('ResidentialMasonry')],1):_vm._e(),_c('section',{staticClass:"section section__map section--no-p"},[_c('ScrollAnchor',{attrs:{"value":"map","offset":"-8rem","delay":750}}),_c('ResidentialMap')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }