<template>
  <div class="summary">
    <div class="summary__price-wrapper">
      <div class="summary__price">
        <h2 class="summary__price-value">{{ price }}</h2>
        <div v-if="hasPrice && item.status === 'sale'" class="summary__price-label">
          <div v-if="$path('sale.jointDebt', item)">
            <template>+ {{ $t('jointDebt').toLowerCase() }}</template>
            <template v-if="jointDebt"> ({{ jointDebt | Currency }})</template>
          </div>
          <template v-if="$path('sale.fee.included', item)">
            {{ $t('residentialSaleFeeIncluded', { value: null }) }}
          </template>
          <template v-else> + {{ $t('residentialSaleFee').toLowerCase() }} </template>
          <template v-if="fee">({{ fee | Currency }})</template>
          <strong v-if="total && total !== price" class="summary__price-total">{{
            `${$t('totalPrice')}: ${total}`
          }}</strong>
        </div>
      </div>
    </div>
    <div
      class="summary__property"
      v-for="(property, index) in properties"
      :key="property.key + index"
      :data-key="property.key"
    >
      <div>
        <div class="summary__property-label">{{ property.label || $tc(property.key) }}</div>
        <div class="summary__property-value">{{ property.formatted }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Currency } from '@/filters'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    hasPrice() {
      return Boolean(this.$path('item.sale.value'))
    },
    total() {
      let total = this.$path('item.sale.value')
      if (!total) return
      if (!this.$path('item.sale.fee.included') && this.fee) total += this.fee
      if (this.jointDebt) total += this.jointDebt
      return Currency(total)
    },
    price() {
      if (!this.item.status) return
      let price = Currency(this.$path('item.sale.value'))
      switch (this.item.status) {
        case 'sale':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return price
        case 'reserved':
          return `${this.$t('residentialStatus:' + this.item.sale.type + '.' + this.item.status)}${
            price && !KvassConfig.get('siteSettings.hideReservedPrice') ? ` (${price})` : ''
          }`
        case 'rent':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return `${price} / ${this.$t('multiplicatorMonthly')}.`
        default:
          return this.$t('residentialStatus:' + this.item.sale.type + '.' + this.item.status)
      }
    },
    fee() {
      return this.$path('item.sale.fee.value')
    },
    bidUrl() {
      return this.$path('item.sale.bidUrl')
    },
    jointDebt() {
      return this.$path('item.sale.jointDebt')
    },
    properties() {
      if (!this.item.properties) return []
      return this.item.properties
        .filter(
          p =>
            (KvassConfig.get('siteSettings.featuredResidentialProperties') || []).includes(p.key) &&
            p.formatted,
        )
        .slice(0, 3)
    },
  },
  filters: {
    Currency,
  },
}
</script>

<style lang="scss">
.summary {
  color: $dark;
  display: flex;
  flex-direction: column;

  @include respond-below('tablet') {
    font-size: 0.8em;
  }

  & > div {
    border-bottom: 1px solid $border-color;
    padding-left: 2em;
    display: flex;
    flex-grow: 1;

    @include respond-below('tablet') {
      padding: 2em;
    }
  }

  & > div > * > *:not(a) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__address {
    font-size: 1.2em;
    display: flex;
    flex-direction: row !important;

    &-dash {
      opacity: 0.3;
      margin: 0 0.5em;
    }
  }

  &__title {
    font-size: 1.5em;
    opacity: 0.8;
  }

  &__price {
    margin-top: 1rem;
    flex-direction: column;
    @include respond-below('tablet') {
      margin-left: 0.5em;
    }
    h2 {
      margin-bottom: 0.3em;
    }
    &-value {
      font-size: 2.3em;
      font-weight: bold;
    }

    &-label {
      opacity: 0.8;
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
  }

  &__property {
    &-label {
      opacity: 0.75;
    }

    &-value {
      font-weight: bold;
      font-size: 1.2em;
      margin-top: 0.25em;
    }
  }
}
</style>
