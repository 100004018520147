<template>
  <div class="project-hero">
    <div class="project-hero__overlay">
      <div
        class="project-hero__overlay-content"
        :class="'project-hero__overlay-content-placement--' + summary.placement"
        :style="style"
      >
        <div class="project-hero__overlay-slogan container">
          <h1 v-if="summary.title" class="project-hero__overlay-title">{{ summary.title }}</h1>
          <div
            v-if="summary.content"
            class="project-hero__overlay-summary"
            v-html="summary.content"
          ></div>
        </div>
        <slot name="overlay" />
      </div>
    </div>
    <div class="project-hero__slide">
      <Slider v-if="items.length" :value="items" display-thumbnail :manual-navigation="false" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView, GetCustomField } from '@/utils'
import Slider from '@kvass/media-render/Slider'
import Lead from '@kvass/lead'
import Properties from './Properties'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },

    summary() {
      const defaultData = {
        placement: 'center',
        title: this.item.name,
        content: this.$tc('getInTouch'),
      }

      let data = GetCustomField('project-hero', this.item.customFields)
      if (!data) return { placement: 'center' }

      return Object.entries(data).reduce((res, [key, value]) => {
        res[key] = value || defaultData[key]
        return res
      }, {})
    },
    style() {
      let ratio = navigator.userAgent.includes('Windows') ? window.devicePixelRatio : 1

      return {
        '--pixel-ratio': ratio,
        'padding-top': ratio > 1 ? '1rem' : '6rem',
        'padding-bottom': ratio > 1 ? '4rem' : '5rem',
      }
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  components: {
    Slider,
    Lead,
    Properties,
  },
}
</script>

<style lang="scss">
.project-hero {
  $height: 90vh;

  position: relative;
  min-height: $height;

  background-color: css-alpha('primary', 0.1);

  display: flex;
  flex-direction: column;

  &__overlay {
    background-color: rgba(black, 0.3);
    z-index: 1;
    width: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-content {
      opacity: 1;
      z-index: 2;
      gap: 2.5rem;

      width: 100%;
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .container {
        max-width: 800px;
        margin: 0 auto;
        text-align: center;
      }
      .elder-button {
        width: 100%;
      }

      .lead__title {
        display: none;
      }

      @include respond-below('phone') {
        gap: 1.5rem;
        font-size: 0.9em;
        h2 {
          font-size: 1.1em;
        }
      }

      &-placement {
        &--none {
          opacity: 0;
        }
        &--left {
          .container {
            max-width: 700px;
            margin-left: 0;
            width: 100%;
            padding-left: 2rem;
            text-align: left;
            @include respond-below('phone') {
              padding-left: 1rem;
            }
          }

          .lead__form-actions {
            justify-content: start;
          }
        }
      }
    }

    &-slogan {
      color: white;
      font-size: clamp(1.25rem, 2vw, 2.25rem);

      h1 {
        font-size: 2em;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0;
      }
    }
  }

  &__slide {
    position: absolute;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    iframe {
      min-height: $height;
    }
  }

  .kvass-media-render-slider {
    width: 100%;

    &__navigation {
      z-index: 1;

      @include respond-below('phone') {
        display: none;
      }
    }
    &__navigation {
      max-height: 300px;
      z-index: 1;
      margin: auto 0;
      position: initial;
      &-button {
        position: absolute;
        z-index: 2;
        top: 50%;
      }
      :nth-child(2) {
        right: 0;
      }
    }
  }
  .elder-button {
    text-align: center;
    &--secondary {
      color: var(--secondary-contrast);
    }
  }
}
</style>
